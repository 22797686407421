@import "~@ui/styles/tools";
@import "src/styles/variables.scss";

@mixin large-breakpoint {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

@mixin info-socials-grid {
    width: 100%;
    justify-content: end;
    justify-self: end;
    align-items: start;
    display: grid;
    grid-template-columns: 438px 209px;
    gap: 80px;

    @include medium-breakpoint {
        grid-template-columns: 250px 170px;
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 170px);
        gap: 40px;
    }

    @include media-tablet {
        gap: 40px;
        padding: 32px 16px;
    }
}

.container {
    background: #FAFAFA;
    display: grid;
    grid-template-areas:
    'navigation info'
    'apps socials'
    'rights rights';

    @include media-tablet {
        grid-template-areas:
        'navigation'
        'info'
        'apps'
        'socials'
        'rights';
    }

    @include media-mobile {
        grid-template-areas: 
        'navigation'
        'info'
        'socials'
        'apps'
        'rights';

        // Add this padding on mobile to prevent Jivo Badge hiding content
        padding-bottom: 60px;
    }
}

.section {
    border-top: 1px solid #CDD5D2;
}

.navigation {
    grid-area: navigation;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 134px;
    grid-auto-flow: column;
    height: min-content;

    &>a, a:visited {
        color: $color-title-lighter;
        font-size: 26px;
    }

    @include medium-breakpoint {
        column-gap: 40px;
        &>a {
            font-size: 20px;
        }
    }

    @include media-tablet {
        padding: 44px 16px 0;
    }

    @include media-mobile {
        grid-template-columns: 1fr !important;
        grid-auto-flow: column;
    }

    @include media-mobile {
        grid-auto-flow: dense;
    }
}

.info {
    @include info-socials-grid();
    grid-area: info;
    padding: 40px 50px 40px 20px;
    font-size: 24px;
    line-height: 28px;

    color: #A2A8AD;

    @include medium-breakpoint {
        font-size: 14px;
        line-height: 16px;
    }

    @include media-tablet {
        padding-bottom: 44px;
        border-top: none;
        grid-template-columns: 1fr 1fr;
    }

    @include media-mobile {
        grid-template-columns: 1fr;
        max-width: 100vw;
    }
}

.support {
    @include media-tablet {
        text-transform: uppercase;
    }
}

.apps {
    grid-area: apps;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 50px;
    display: flex;
    gap: 14px;

    &>a {
        min-width: 90px;
    }

    @include media-tablet {
        border-top: 1px solid #CDD5D2;
        padding: 44px 16px 24px;
        gap: 12px;
        display: grid;
        grid-template-columns: 170px 170px 170px 170px;
        justify-content: center;


        &>a {
            min-width: auto;
        }
    }

    @include media-tablet-portrait {
        grid-template-columns: 170px 170px;
    }

    @include media-mobile {
        border-top: none;
        padding: 0 16px 44px;
        justify-content: space-between;
        gap: 0;
        row-gap: 12px;
    }

    @include media-mobile-xs {
        grid-template-columns: 135px 135px;
    }
}

.socials {
    // @include info-socials-grid
    grid-area: socials;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 50px;
    padding-left: 20px;

    display: grid;
    grid-template-columns: repeat(7, max-content);
    gap: 16px;
    align-items: center;

    &>span {
        max-width: 170px;
    }

    &__label {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5%;
    }

    @include medium-breakpoint {
        gap: 14px;
    }

    @include media-tablet {
        border-top: none;
        gap: 12px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px 44px;
        justify-content: center;

        &>span {
            max-width: 100%;
        }
    }

    @include media-mobile {
        display: grid;
        grid-template-columns: repeat(3, 55px);
        grid-template-rows: auto auto;
        border-top: 1px solid #CDD5D2;
        padding: 44px 16px 24px;
        justify-content: start;

        & div:first-child {
            grid-column: 1 / -1;
            height: 35px;
        }
    }
}

.rights {
    grid-area: rights;
    padding: 62px 50px 54px 50px;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include medium-breakpoint {
        .copyright__text {
            max-width: 500px;
        }

        .license { 
            max-width: 400px;
        }
    }

    @include media-tablet {
        padding: 40px 16px;
        font-size: 16px;
    }

    @include media-mobile {
        flex-direction: column;
        gap: 16px;
    }
}

.copyright__text {
    color: #949494;
    font-size: 18px;
    max-width: 660px;

    @include media-tablet {
        font-size: 14px;
    }
}

.copyright__text + .copyright__text {
    margin-top: 20px;
}

.copyright__links {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.notes {
    padding: 40px 16px;
}

.address__highlight {
    color: $color-title-active;

    margin-bottom: 20px;

    @include media-tablet {
        margin-bottom: 10px;
    }
}

.address__text > a {
    color: #A2A8AD;
}

.address__text + .address__text {
    margin-top: 20px;

    @include media-tablet {
        margin-top: 10px;
    }
}
